/* eslint-disable prefer-destructuring */
/**
 * Client side
 */
export const API_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const BO_API_URL = process.env.NEXT_PUBLIC_API_BO;

export const PIGSPIN_API_URL = process.env.NEXT_PUBLIC_PIGSPIN_API_BASE_URL
  || process.env.NEXT_PUBLIC_API_BASE_URL;
export const QR_PAYMENT_API_URL = process.env.NEXT_PUBLIC_API_BASE_URL_V2;

export const WEB_URL = process.env.NEXT_PUBLIC_WEB_URL || 'https://pigspin.net/';
export const SOCKET_ENDPOINT = process.env.NEXT_PUBLIC_SOCKET_ENDPOINT || 'https://api.pigspin.dev';
export const CLOUD_STORAGE_CDN = process.env.NEXT_PUBLIC_CLOUD_STORAGE_CDN || 'https://assets.pigspin.dev';
export const CLOUD_SHARED_STORAGE_CDN = process.env.NEXT_PUBLIC_CLOUD_SHARED_STORAGE_CDN || 'https://assets.bgbsys.com';

export const PRODUCT_NAME = process.env.NEXT_PUBLIC_PRODUCT || 'PIGSPIN';
export const PRODUCT_LINE_ACCOUNT = process.env.NEXT_PUBLIC_PRODUCT_LINE_ACCOUNT || 'PIGSPIN';
export const PRODUCT_EMAIL = process.env.NEXT_PUBLIC_PRODUCT_EMAIL || 'support@pigspin.in';
export const LINE_CONTACT_URL = process.env.NEXT_PUBLIC_LINE_URL || 'https://www.pigspin.in/line';
export const APP_GAME_NAME = process.env.NEXT_PUBLIC_GAME_NAME || 'PIGSPIN';
export const FACEBOOK_OFFICIAL_URL = process.env.NEXT_PUBLIC_FACEBOOK_OFFICIAL_URL || 'https://www.pigspin.in/facebook';
export const FACEBOOK_MESSENGER_URL = process.env.NEXT_PUBLIC_FACEBOOK_MESSENGER_URL || 'https://www.pigspin.in/facebookmessenger';
export const TWITTER_OFFICIAL_URL = process.env.NEXT_PUBLIC_TWITTER_OFFICIAL_URL || 'https://www.pigspin.in/twitter';

export const SHARED_ASSET_URL = `${CLOUD_STORAGE_CDN}/shared-assets`;
export const ASSET_URL = `${CLOUD_STORAGE_CDN}/${process.env.NEXT_PUBLIC_ASSET_FOLDER || 'pigspin-assets'}`;
export const IMAGE_FOLDER_PATH = '/images';
export const FONT_FOLDER_PATH = '/fonts';
export const VIDEO_FOLDER_PATH = '/video';
export const FAVICON_IMAGE_PATH = '/favicon';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const OPT_TRACKING_ID = process.env.NEXT_PUBLIC_OPT_ID;
export const PROPELLER_ADS_PARTNER_ID = process.env.NEXT_PUBLIC_PROPELLER_ADS_PARTNER_ID || '';
export const PUBNUB_PUBLIC_KEY = process.env.NEXT_PUBLIC_PUBNUB_PUBLIC_KEY || '';
export const PUBNUB_SUBSCRIBE_KEY = process.env.NEXT_PUBLIC_PUBNUB_SUBSCRIBE_KEY || '';
export const FRESHCHAT_TOKEN = process.env.NEXT_PUBLIC_FRESHCHAT_TOKEN || '';

export const LINE_CHANNEL_ID = process.env.NEXT_PUBLIC_LINE_CHANNEL_ID || '';
// !!! Dont make it PUBLIC
export const LINE_CLIENT_SECRET = process.env.LINE_CLIENT_SECRET || '';

// NOTE : Bank Asset Data
export const BANK_IMAGE_PATH = `${CLOUD_SHARED_STORAGE_CDN}/bank-icon/logo`;
export const BANK_APP_IMAGE_PATH = `${CLOUD_SHARED_STORAGE_CDN}/bank-icon/app`;

export const PG_GAME_DEMO_TOKEN = process.env.NEXT_PUBLIC_PG_DEMO_TOKEN || '';
export const PG_GAME_FULL_TOKEN = process.env.NEXT_PUBLIC_PG_FULL_TOKEN || '';
export const PG_GAME_DEMO_URL = process.env.NEXT_PUBLIC_PG_DEMO_URL || '';
export const PG_GAME_FULL_URL = process.env.NEXT_PUBLIC_PG_FULL_URL || '';

export const CURRENT_ENV = process.env.ENV_TYPE || '';

export const JOKER_GAME_FULL_URL = process.env.NEXT_PUBLIC_JOKER_FULL_URL || '';
export const JOKER_GAME_APP_ID = process.env.NEXT_PUBLIC_JOKER_APP_ID || '';

/**
 * Server side
 */
